<template>
	<div class="home">
		<v-card>
            <v-container fluid>
				<v-row><v-col cols="12"></v-col></v-row>
                <OrderSearch/>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import OrderSearch from "./component/ComponentOrderSearch";
import { permission } from "@components/mixins/permission";
import Breadcrumbs from "@components/Breadcrumbs";

export default {
    components: { OrderSearch, Breadcrumbs },
    mixins: [permission],
    data () {
        return {};
    },
    mounted () {}
};
</script>
