<template>
    <div>
        <v-row>
            <v-col cols="12"></v-col>
        </v-row>
        
        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                        <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                            SEARCH FILTER
                        </div>
                    </v-card-title>

                    <v-row class="pa-4">
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2" v-if="brokerMap.length > 1">
                            <v-autocomplete label="Filter Broker" v-model="selectedBrokerId" :items="brokerMap" item-text="broker"
                                item-value="broker_id" auto-select-first chips clearable deletable-chips small-chips>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete label="Filter Book" v-model="selectedBook" :items="filterBookLists" item-text="book"
                                item-value="book" auto-select-first chips clearable deletable-chips multiple small-chips>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete v-model="selectedPendingOrMarket"
                                :items="[{name: 'Market', value: 'market'},{name: 'Pending', value: 'pending'}]" item-text="name"
                                item-value="value" label="Filter Pending/Market" auto-select-first chips clearable deletable-chips
                                small-chips></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete v-model="selectedCmd" :items="filterCmdLists" item-text="name" item-value="cmd"
                                label="Filter Order Type" auto-select-first chips clearable deletable-chips multiple small-chips>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <div class="text-left" style="margin-left:20px;margin-top:20px">
                                <div>
                                    <v-btn text icon color="blue lighten-2" @click="showMore()" v-if="!showMoreStatus">
                                        More<v-icon large>mdi-chevron-down</v-icon>
                                    </v-btn>
                                    <v-btn text icon color="blue lighten-2" @click="showMore()" v-if="showMoreStatus">
                                        Fewer<v-icon large>mdi-chevron-up</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="pa-4" v-if="showMoreStatus">
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete v-model="selectedReason" :items="filterReasonLists" item-text="name" item-value="value"
                                label="Filter Reason" auto-select-first chips clearable deletable-chips multiple small-chips>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-text-field label="Enter Symbol to Search" v-model="selectedSymbol" hide-details="auto">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-switch style="width: 100%;" v-model="selectedIsOpen" :label="`Is Opening Trade?`"></v-switch>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-datetime-picker label="Filter Open Time (Start)" v-model="selectedOpenTimeStart"></v-datetime-picker>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-datetime-picker label="Filter Open Time (End)" v-model="selectedOpenTimeEnd"></v-datetime-picker>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2" v-if="toggleCloseFilter">
                            <v-datetime-picker label="Filter Close Time (Start)" v-model="selectedCloseTimeStart">
                            </v-datetime-picker>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2" v-if="toggleCloseFilter">
                            <v-datetime-picker label="Filter Close Time (End)" v-model="selectedCloseTimeEnd"></v-datetime-picker>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete label="Order By" v-model="selectedOrderBy" :items="filterOrderBy" auto-select-first
                                chips clearable deletable-chips small-chips></v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                            <v-switch style="width: 100%;" v-model="isAsc" :label="`Is Ascending Order?`"></v-switch>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete label="Show Result" v-model="selectedLimit" :items="filterShowResult" auto-select-first
                                chips clearable deletable-chips small-chips></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-text-field label="Filter Login (Comma to Seprate Multi Login, Eg: 100000000,200000000,30000000)"
                                v-model="selectedLogin" hide-details="auto"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="pa-4">
                        <v-col lg="12" md="12" xs="12" cols="12">
                            <v-btn v-on:click="fetchTradeRecord()" :loading="btnLoading" color="primary">SEARCH</v-btn>
                        </v-col>
                    </v-row>

                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                        <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                            RESULT
                        </div>
                        <div class="text-right" style="margin-right: 0; width: 50%;">
                            <download-excel :data="tradesList" :name="'Search'">
                                <v-icon color="green" dark dense>
                                    mdi-microsoft-excel
                                </v-icon>
                            </download-excel>
                        </div>
                    </v-card-title>

                    <v-data-table dense :search="search" :headers="headers" :loading="tableLoading"
                        :items="filteredDataSource" class="elevation-1" :items-per-page="15">
                        <template v-slot:header.login="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`loginName`)">
                                        <v-icon small :color="loginName ? 'primary' : ''">
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div style="background-color: #1e1e1e; width: 280px; ">
                                    <v-text-field v-model="loginName" class="px-4" type="text" ref="loginName"
                                        label="Enter the search term" style="width: 100%;"></v-text-field>
                                    <v-btn @click="loginName = ''" small text color="primary" class="ml-2 mb-2">
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.ticket="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`ticketName`)">
                                        <v-icon small :color="ticketName ? 'primary' : ''">
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div style="background-color: #1e1e1e; width: 280px; ">
                                    <v-text-field v-model="ticketName" class="px-4" type="text" ref="ticketName"
                                        label="Enter the search term" style="width: 100%;"></v-text-field>
                                    <v-btn @click="ticketName = ''" small text color="primary" class="ml-2 mb-2">
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.symbol="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`symbolName`)">
                                        <v-icon small :color="symbolName ? 'primary' : ''">
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div style="background-color: #1e1e1e; width: 280px; ">
                                    <v-text-field v-model="symbolName" class="px-4" type="text" ref="symbolName"
                                        label="Enter the search term" style="width: 100%;"></v-text-field>
                                    <v-btn @click="symbolName = ''" small text color="primary" class="ml-2 mb-2">
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.cmd="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon small :color="cmdName ? 'primary' : ''">
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div style="background-color: #1e1e1e; width: 150px">
                                    <v-container class="pb-0 pt-1" fluid>
                                        <v-radio-group v-model="cmdName" class="mt-0 mb-0 pb-0">
                                            <v-radio label="Buy" value="Buy"></v-radio>
                                            <v-radio label="Sell" value="Sell"></v-radio>
                                        </v-radio-group>
                                    </v-container>
                                    <v-btn @click="cmdName = ''" small text color="primary" class="ml-2 mb-2">
                                        Clean</v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.comment="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`commentName`)">
                                        <v-icon small :color="commentName ? 'primary' : ''">
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div style="background-color: #1e1e1e; width: 280px; ">
                                    <v-text-field v-model="commentName" class="px-4" type="text" ref="commentName"
                                        label="Enter the search term" style="width: 100%;"></v-text-field>
                                    <v-btn @click="commentName = ''" small text color="primary" class="ml-2 mb-2">
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.reason="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon small :color="reasonName ? 'primary' : ''">
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div style="background-color: #1e1e1e; width: 150px">
                                    <v-container class="pb-0 pt-1" fluid>
                                        <v-radio-group v-model="reasonName" class="mt-0 mb-0 pb-0">
                                            <v-radio label="CLIENT" value="CLIENT"></v-radio>
                                            <v-radio label="EXPERT" value="EXPERT"></v-radio>
                                            <v-radio label="DEALER" value="DEALER"></v-radio>
                                            <v-radio label="SIGNAL" value="SIGNAL"></v-radio>
                                            <v-radio label="GATEWAY" value="GATEWAY"></v-radio>
                                            <v-radio label="MOBILE" value="MOBILE"></v-radio>
                                            <v-radio label="WEB" value="WEB"></v-radio>
                                            <v-radio label="API" value="API"></v-radio>
                                        </v-radio-group>
                                    </v-container>
                                    <v-btn @click="reasonName = ''" small text color="primary" class="ml-2 mb-2">
                                        Clean</v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.volume="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`volumeName`)">
                                        <v-icon small :color="volumeName ? 'primary' : ''">
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div style="background-color: #1e1e1e; width: 280px; ">
                                    <v-text-field v-model="volumeName" class="px-4" type="text" ref="volumeName"
                                        label="Volume >=" style="width: 100%;"></v-text-field>
                                    <v-btn @click="volumeName = ''" small text color="primary" class="ml-2 mb-2">
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.open_price="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`openPriceName`)">
                                        <v-icon small :color="openPriceName ? 'primary' : ''">
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div style="background-color: #1e1e1e; width: 280px; ">
                                    <v-text-field v-model="openPriceName" class="px-4" type="text" ref="openPriceName"
                                        label="Open Price >=" style="width: 100%;"></v-text-field>
                                    <v-btn @click="openPriceName = ''" small text color="primary" class="ml-2 mb-2">
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>

                        <template v-slot:header.open_time="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon small
                                            :color="(openTimeRange[0] !=openTimeMin||openTimeRange[1]!=openTimeMax) ? 'primary' : ''">
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div style="background-color: #1e1e1e; width: 500px; ">
                                    <v-card flat>
                                        <!-- <v-subheader>Min and max range slider</v-subheader> -->
                                        <v-card-text>
                                            <v-row>
                                                <v-col class="px-4">
                                                    <v-range-slider v-model="openTimeRange" :max="openTimeMax"
                                                        :min="openTimeMin" hide-details class="align-center">
                                                        <template v-slot:prepend>
                                                            <v-text-field :value="timehelper(openTimeRange[0])"
                                                                class="mt-0 pt-0" hide-details single-line disabled
                                                                dense style="width: 147px"
                                                                @change="$set(openTimeRange, 0, $event)"></v-text-field>
                                                        </template>
                                                        <template v-slot:append>
                                                            <v-text-field :value="timehelper(openTimeRange[1])"
                                                                class="mt-0 pt-0" hide-details single-line disabled
                                                                dense style="width: 147px"
                                                                @change="$set(openTimeRange, 1, $event)"></v-text-field>
                                                        </template>
                                                    </v-range-slider>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-btn @click="openTimeRange = [openTimeMin,openTimeMax]" small text
                                                    color="primary" class="ml-2 mb-2">
                                                    Reset
                                                </v-btn>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </v-menu>
                        </template>

                        <template v-slot:header.close_time="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon small
                                            :color="(closeTimeRange[0] !=closeTimeMin||closeTimeRange[1]!=closeTimeMax) ? 'primary' : ''">
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div style="background-color: #1e1e1e; width: 500px; ">
                                    <v-card flat>
                                        <!-- <v-subheader>Min and max range slider</v-subheader> -->
                                        <v-card-text>
                                            <v-row>
                                                <v-col class="px-4">
                                                    <v-range-slider v-model="closeTimeRange" :max="closeTimeMax"
                                                        :min="closeTimeMin" hide-details class="align-center">
                                                        <template v-slot:prepend>
                                                            <v-text-field :value="timehelper(closeTimeRange[0])"
                                                                class="mt-0 pt-0" hide-details single-line disabled
                                                                dense style="width: 147px"
                                                                @change="$set(closeTimeRange, 0, $event)">
                                                            </v-text-field>
                                                        </template>
                                                        <template v-slot:append>
                                                            <v-text-field :value="timehelper(closeTimeRange[1])"
                                                                class="mt-0 pt-0" hide-details single-line disabled
                                                                dense style="width: 147px"
                                                                @change="$set(closeTimeRange, 1, $event)">
                                                            </v-text-field>
                                                        </template>
                                                    </v-range-slider>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-btn @click="closeTimeRange = [closeTimeMin,closeTimeMax]" small text
                                                    color="primary" class="ml-2 mb-2">
                                                    Reset
                                                </v-btn>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </v-menu>
                        </template>

                        <template v-slot:header.close_price="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`closePriceName`)">
                                        <v-icon small :color="closePriceName ? 'primary' : ''">
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div style="background-color: #1e1e1e; width: 280px; ">
                                    <v-text-field v-model="closePriceName" class="px-4" type="text" ref="closePriceName"
                                        label="Close Price >=" style="width: 100%;"></v-text-field>
                                    <v-btn @click="closePriceName = ''" small text color="primary" class="ml-2 mb-2">
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.profit="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`profitName`)">
                                        <v-icon small :color="profitName ? 'primary' : ''">
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div style="background-color: #1e1e1e; width: 280px; ">
                                    <v-text-field v-model="profitName" class="px-4" type="text" ref="profitName"
                                        label="Profit >=" style="width: 100%;"></v-text-field>
                                    <v-btn @click="profitName = ''" small text color="primary" class="ml-2 mb-2">
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.sl="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`slName`)">
                                        <v-icon small :color="slName ? 'primary' : ''">
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div style="background-color: #1e1e1e; width: 280px; ">
                                    <v-text-field v-model="slName" class="px-4" type="text" ref="slName" label="SL >="
                                        style="width: 100%;"></v-text-field>
                                    <v-btn @click="slName = ''" small text color="primary" class="ml-2 mb-2">
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.tp="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`tpName`)">
                                        <v-icon small :color="tpName ? 'primary' : ''">
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div style="background-color: #1e1e1e; width: 280px; ">
                                    <v-text-field v-model="tpName" class="px-4" type="text" ref="tpName" label="TP >="
                                        style="width: 100%;"></v-text-field>
                                    <v-btn @click="tpName = ''" small text color="primary" class="ml-2 mb-2">
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.swaps="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`swapsName`)">
                                        <v-icon small :color="swapsName ? 'primary' : ''">
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div style="background-color: #1e1e1e; width: 280px; ">
                                    <v-text-field v-model="swapsName" class="px-4" type="text" ref="swapsName"
                                        label="Swaps <=" style="width: 100%;"></v-text-field>
                                    <v-btn @click="swapsName = ''" small text color="primary" class="ml-2 mb-2">
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                        <template v-slot:header.commission="{ header }">
                            {{ header.text }}
                            <v-menu offset-y :close-on-content-click="false" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`commissionName`)">
                                        <v-icon small :color="commissionName ? 'primary' : ''">
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <div style="background-color: #1e1e1e; width: 280px; ">
                                    <v-text-field v-model="commissionName" class="px-4" type="text" ref="commissionName"
                                        label="Commission <=" style="width: 100%;"></v-text-field>
                                    <v-btn @click="commissionName = ''" small text color="primary" class="ml-2 mb-2">
                                        Clean
                                    </v-btn>
                                </div>
                            </v-menu>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import * as apiBook from "@components/api/pk/book";
import * as apiGroup from "@components/api/pk/group";
import * as apiSymbol from "@components/api/pk/symbol";
import * as apiTrade from "@components/api/pk/trades";
import DataTable from "@components/search/DataTable";
import { snackbar } from "@components/mixins/snackbar";
import { permission } from "@components/mixins/permission";
import { helper } from "@components/mixins/helper";
import { mapState } from "vuex";

export default {
    components: { DataTable },
    mixins: [permission, snackbar, helper],
    data () {
        return {
            showMoreStatus: false,
            ticketName: "",
            loginName: "",
            symbolName: "",
            cmdName: "",
            commentName: "",
            reasonName: "",
            volumeName: "",
            openPriceName: "",
            closePriceName: "",
            profitName: "",
            slName: "",
            tpName: "",
            commissionName: "",
            swapsName: "",
            openTimeMin: 20,
            openTimeMax: 50,
            openTimeRange: [20, 50],
            openTimePeriod: [],
            closeTimeMin: 20,
            closeTimeMax: 50,
            closeTimeRange: [20, 50],
            closeTimePeriod: [],
            btnLoading: false,
            tableLoading: false,
            selectedOffsetStart: 0,
            selectedLimit: 100,
            selectedOrderBy: "open_time",
            orderByLists: [
                "ticket",
                "login",
                "open_time",
                "close_time",
                "profit"
            ],
            filterShowResult: [100, 200, 400, 600, 1000, 2000],
            isAsc: false,
            searchloading: false,
            selectedLogin: "",
            selectedCmd: [0, 1],
            selectedReason: [],
            selectedBrokerId: 1,
            selectedGroup: [],
            selectedBook: [],
            selectedSymbol: "",
            selectedOpenTimeStart: null,
            selectedOpenTimeEnd: null,
            selectedCloseTimeStart: null,
            selectedCloseTimeEnd: null,
            selectedIsOpen: false,
            toggleCloseFilter: true,
            filterOrderBy: [
                "login",
                "ticket",
                "symbol",
                "cmd",
                "volume",
                "open_time",
                "open_price",
                "close_time",
                "close_price",
                "profit",
                "sl",
                "tp",
                "swaps",
                "commission",
                "comment",
                "reason"
            ],
            filterGroupLists: [],
            filterSymbolLists: [],
            filterBookLists: [],
            selectedPendingOrMarket: "market",

            filterCmdLists: [
                { name: "Buy", cmd: 0 },
                { name: "Sell", cmd: 1 }
            ],

            filterCmdMarket: [
                { name: "Buy", cmd: 0 },
                { name: "Sell", cmd: 1 }
            ],
            filterCmdPending: [
                { name: "Buy Limit", cmd: 2 },
                { name: "Sell Limit", cmd: 3 },
                { name: "Buy Stop", cmd: 4 },
                { name: "Sell Stop", cmd: 5 }
            ],

            filterReasonLists: [
                { value: 0, name: "CLIENT" },
                { value: 1, name: "EXPERT" },
                { value: 2, name: "DEALER" },
                { value: 3, name: "SIGNAL" },
                { value: 4, name: "GATEWAY" },
                { value: 5, name: "MOBILE" },
                { value: 6, name: "WEB" },
                { value: 7, name: "API" }
            ],

            tradesList: [],
            csvHeader: {
                book: "Book",
                login: "Login",
                ticket: "Ticket",
                symbol: "Symbol",
                cmd: "Type",
                volume: "Volume",
                open_time: "OpenTime",
                open_price: "OpenPrice",
                close_time: "CloseTime",
                close_price: "ClosePrice",
                profit: "Profit",
                sl: "Sl",
                tp: "Tp",
                swaps: "Swaps",
                commission: "Commission",
                comment: "Comment",
                reason: "Reason"
            },
            tradesSymbol: [],

            search: "",
            headers: [
                // { text: 'Book', value: 'book' },
                { text: "Login", value: "login" },
                { text: "Ticket", value: "ticket" },
                { text: "Symbol", value: "symbol" },
                { text: "Type", value: "cmd" },
                { text: "Volume", value: "volume" },
                { text: "Open Time", value: "open_time" },
                { text: "Open Price", value: "open_price" },
                { text: "Close Time", value: "close_time" },
                { text: "Close Price", value: "close_price" },
                { text: "Profit", value: "profit" },
                { text: "SL", value: "sl" },
                { text: "TP", value: "tp" },
                { text: "Swaps", value: "swaps" },
                { text: "Commission", value: "commission" },
                { text: "Comment", value: "comment" },
                { text: "Reason", value: "reason" }
            ],
            brokers: [],
            root: 0
        };
    },
    computed: {
        ...mapState(["brokerMap"]),
        filteredDataSource () {
            const conditions = [];
            if (this.loginName) {
                conditions.push(this.filterLoginName);
            }
            if (this.commentName) {
                conditions.push(this.filterCommentName);
            }
            if (this.symbolName) {
                conditions.push(this.filterSymbolName);
            }
            if (this.reasonName) {
                conditions.push(this.filterReasonName);
            }
            if (this.ticketName) {
                conditions.push(this.filterTicketName);
            }
            if (this.cmdName) {
                conditions.push(this.filterCmdName);
            }
            if (
                this.openTimeRange[0] != this.openTimeMin ||
                this.openTimeRange[1] != this.openTimeMax
            ) {
                conditions.push(this.filterOpenTimeRange);
            }
            if (
                this.closeTimeRange[0] != this.closeTimeMin ||
                this.closeTimeRange[1] != this.closeTimeMax
            ) {
                conditions.push(this.filterCloseTimeRange);
            }
            if (this.volumeName) {
                conditions.push(this.filterVolumeName);
            }
            if (this.openPriceName) {
                conditions.push(this.filterOpenPriceName);
            }
            if (this.closePriceName) {
                conditions.push(this.filterClosePriceName);
            }
            if (this.profitName) {
                conditions.push(this.filterProfitName);
            }
            if (this.slName) {
                conditions.push(this.filterSlName);
            }
            if (this.tpName) {
                conditions.push(this.filterTpName);
            }
            if (this.commissionName) {
                conditions.push(this.filterCommissionName);
            }
            if (this.swapsName) {
                conditions.push(this.filterSwapsName);
            }

            if (conditions.length > 0) {
                return this.tradesList.filter((item) => {
                    return conditions.every((condition) => {
                        return condition(item);
                    });
                });
            }
            return this.tradesList;
        }
    },
    watch: {
        selectedBrokerId (nv, ov) {
            this.filterBookLists = this.getBookListByBrokerId(nv);
            this.loadGroup();
        },
        selectedPendingOrMarket (nv, ov) {
            if (nv == "market") {
                this.filterCmdLists = this.filterCmdMarket;
            }
            if (nv == "pending") {
                this.filterCmdLists = this.filterCmdPending;
            }
        },
        selectedIsOpen (nv, ov) {
            if (nv == true) {
                this.selectedCloseTimeStart = null;
                this.selectedCloseTimeEnd = null;
                this.toggleCloseFilter = false;
            } else {
                this.toggleCloseFilter = true;
            }
        }
    },
    methods: {
        // tableEventOptions(data) {
        //     if (data.sortBy.length == 0 || data.sortDesc.length == 0) return;
        //     this.selectedOrderBy = data.sortBy[0];
        //     this.isAsc = !data.sortDesc[0];
        //     this.fetchTradeRecord();
        // },
        changeView (key) {},
        toCSV () {
            // this.$ToCSV("SearchResult",this.csvHeader,this.tradesList)
        },
        /**
         * Fetch Symbol Lists
         * @return {[type]}
         */
        async fetchSymbol () {
            const self = this;

            apiSymbol.fetch().then((res) => {
                const data = res.data.data;
                self.filterSymbolLists = data;
            });
        },
        /**
         * Fetch users book group mapping
         * @return {[type]}
         */
        async loadGroup () {
            const self = this;
            const params = { broker_id: this.selectedBrokerId };
            apiGroup.fetch(params).then((res) => {
                // let data = res.data.data;
                self.filterGroupLists = res.data;
            });
        },
        async fetchTradeRecord () {
            const self = this;

            const finalFilterSymbol = [];

            if (
                self.selectedSymbol != "" &&
                self.selectedSymbol != undefined &&
                self.selectedSymbol != null
            ) {
                finalFilterSymbol.push(self.selectedSymbol);
                for (
                    let i = 0;
                    i < this.$store.state.symbolEndfix.length;
                    i++
                ) {
                    let endFixSymbol = "";
                    if (this.$store.state.symbolEndfix[i].indexOf("-") != -1) {
                        endFixSymbol =
                            self.selectedSymbol +
                            this.$store.state.symbolEndfix[i].toUpperCase();
                    } else {
                        endFixSymbol =
                            self.selectedSymbol +
                            this.$store.state.symbolEndfix[i].toLowerCase();
                    }
                    finalFilterSymbol.push(endFixSymbol);
                }
            }

            if (
                self.selectedPendingOrMarket == "" ||
                self.selectedPendingOrMarket == null
            ) {
                self.snackBarDanger("Filter Pending/Market cannot be empty!");
                return;
            }

            const params = {
                selectedPendingOrMarket: self.selectedPendingOrMarket,
                broker_id: self.selectedBrokerId,
                selectedLogin: self.selectedLogin,
                selectedCmd: self.selectedCmd,
                selectedReason: self.selectedReason,
                selectedGroup: self.selectedGroup,
                selectedBook: self.selectedBook,
                selectedSymbol: finalFilterSymbol,
                selectedOpenTimeStart: self.selectedOpenTimeStart,
                selectedOpenTimeEnd: self.selectedOpenTimeEnd,
                selectedCloseTimeStart: self.selectedCloseTimeStart,
                selectedCloseTimeEnd: self.selectedCloseTimeEnd,
                selectedIsOpen: self.selectedIsOpen,
                selectedLimit: self.selectedLimit,
                selectedOffsetStart: self.selectedOffsetStart,
                selectedOrderBy: self.selectedOrderBy,
                isAsc: self.isAsc
            };

            self.btnLoading = true;
            self.tableLoading = true;
            self.tradesList = [];
            apiTrade.create(params).then((res) => {
                const data = res.data;
                self.tradesList = data;
                self.btnLoading = false;
                self.tableLoading = false;
                for (const i of self.tradesList) {
                    this.openTimePeriod.push(Date.parse(i.open_time));
                    this.closeTimePeriod.push(Date.parse(i.close_time));
                }
                this.openTimeMin = Math.min.apply(Math, this.openTimePeriod);
                this.openTimeMax = Math.max.apply(Math, this.openTimePeriod);
                this.openTimeRange = [this.openTimeMin, this.openTimeMax];
                this.closeTimeMin = Math.min.apply(Math, this.closeTimePeriod);
                this.closeTimeMax = Math.max.apply(Math, this.closeTimePeriod);
                this.closeTimeRange = [this.closeTimeMin, this.closeTimeMax];
            });
        },
        timehelper (time) {
            const timeString = new Date(time).toLocaleString("en-GB");
            const newTime =
                timeString.substr(6, 4) +
                "-" +
                timeString.substr(3, 2) +
                "-" +
                timeString.substr(0, 2) +
                " " +
                timeString.substr(12, 8);
            return newTime;
        },
        remove (item) {
            this.chips.splice(this.chips.indexOf(item), 1);
            this.chips = [...this.chips];
        },
        setFocus (item) {
            setTimeout(() => {
                this.$refs[`${item}`].focus();
            }, 200);
        },
        filterLoginName (item) {
            return item.login.toString().includes(this.loginName);
        },
        filterTicketName (item) {
            return item.ticket.toString().includes(this.ticketName);
        },
        filterSymbolName (item) {
            return item.symbol
                .toLowerCase()
                .includes(this.symbolName.toLowerCase());
        },
        filterCommentName (item) {
            return item.comment
                .toLowerCase()
                .includes(this.commentName.toLowerCase());
        },
        filterCmdName (item) {
            return item.cmd == this.cmdName;
        },
        filterReasonName (item) {
            return item.reason == this.reasonName;
        },
        filterVolumeName (item) {
            return item.volume >= this.volumeName;
        },
        filterOpenPriceName (item) {
            return item.open_price >= this.openPriceName;
        },
        filterClosePriceName (item) {
            return item.close_price >= this.closePriceName;
        },
        filterProfitName (item) {
            return item.profit >= this.profitName;
        },
        filterSlName (item) {
            return item.sl >= this.slName;
        },
        filterTpName (item) {
            return item.tp >= this.tpName;
        },
        filterSwapsName (item) {
            return item.swaps <= this.swapsName;
        },
        filterCommissionName (item) {
            return item.commission <= this.commissionName;
        },
        showMore () {
            this.showMoreStatus = !this.showMoreStatus;
        },
        filterOpenTimeRange (item) {
            return !!(Date.parse(item.open_time) >= this.openTimeRange[0] &&
                Date.parse(item.open_time) <= this.openTimeRange[1]);
        },
        filterCloseTimeRange (item) {
            return !!(Date.parse(item.close_time) >= this.closeTimeRange[0] &&
                Date.parse(item.close_time) <= this.closeTimeRange[1]);
        }
    },
    created () {
        // 获取当前账户对应权限的broker及broker id
        const brokerPermission = this.loadDefaultBrokerData();
        this.selectedBrokerId = brokerPermission.selectedBrokerId;
        this.selectedBroker = brokerPermission.selectedBroker;
    },
    mounted () {
        this.filterBookLists = this.getBookListByBrokerId(
            this.selectedBrokerId
        );

        this.loadGroup();
    }
};
</script>
<style>
.v-data-table-header__icon {
    margin-left: -2px;
}
table > thead > tr > th:nth-child(n) > button {
    margin-left: -13px;
    margin-right: -10px;
    width: 10%;
}
</style>
